import { AirdaleResponse, Response } from '../../../assets/types/Response';
import { getAirdaleParams } from '../../../sharedModules/getAirdaleParams';
import { getJSONFn, RequestType } from '../../types/GetJSON';

export const getCategoryAirdaleInfo = (
  site: string,
  category: string,
  territory: string,
  getJSON: getJSONFn<RequestType.AIRDALE_REQUEST>,
): Promise<Response<AirdaleResponse>> => {
  const MAX_AIREDALE_RESULTS = 100;
  const params = getAirdaleParams(site, MAX_AIREDALE_RESULTS, category, territory);
  return getJSON(process.env.AIRDALE_ENDPOINT || '', params);
};
