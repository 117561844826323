import React from 'react';

import { getNameBlock } from '../../../../sharedModules/getNameBlock';
import getImageUrl from '../../../modules/getImageUrl';
import { Promo } from '../../../types/Deal';
import LazyImage from '../../GenericComponents/LazyLoad/LazyImage';

import styles from './tile.css';

interface TileProps {
  logoUrl: string;
  title: string;
  exclusive?: boolean;
  description: string;
  promos?: Promo[];
}

export const Tile: React.FC<TileProps> = ({
  logoUrl,
  title,
  exclusive,
  description,
  promos = [],
}) => {
  return (
    <div className={styles['tile-container']}>
      <div className={styles['tile-header-container']}>
        <div className={styles['image-container']}>
          <LazyImage src={logoUrl} alt="offer-logo" />
        </div>
        <div className={styles['offer-title']}>
          <h3 title={title}>{title}</h3>
          {Boolean(exclusive) && (
            <div className={styles['exclusive-container']}>
              <div className={styles['star-image-container']}>
                <LazyImage src={getImageUrl('misc/star.png')} alt="star" />
              </div>
              Exclusive
            </div>
          )}
        </div>
      </div>
      <div>
        <div title={description} className={styles['offer-description']}>
          {getNameBlock(description, promos, false, true)}
        </div>
      </div>
    </div>
  );
};
