import React from 'react';

import classNames from 'classnames';

import type { Promo } from '../../core/types/Deal';

import styles from './styles/get-name-block.css';

/**
 * Coloring deal.promos.display_value substring of deal.offer.name with site color
 * when deal.promos element with type of deal_text exists
 */
export const getNameBlock = (
  dealName: string,
  promos: Promo[] = [],
  expiredDealTime?: boolean,
  bold?: boolean,
): JSX.Element => {
  const Tag = expiredDealTime ? 'div' : 'h3';

  const dealTextPromo = promos
    ?.find((promo) => promo.type === 'deal_text')
    ?.display_value?.toLowerCase();
  if (dealTextPromo) {
    const dealTextLowercase = dealName.toLowerCase();
    const escapedDealTextPromo = dealTextPromo.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const regex = new RegExp(escapedDealTextPromo, 'i');
    const match = dealTextLowercase.match(regex);
    const matchIndex = (match && match.index) || 0;

    if (match) {
      return (
        <Tag key="name" className={styles['offer-name']}>
          {matchIndex !== 0 && dealName.substring(0, matchIndex)}
          <span className={classNames(styles['colored'], bold ? styles['bold'] : null)}>
            {dealName.substring(matchIndex, matchIndex + match['0'].length)}
          </span>
          {dealName.substring(matchIndex + match['0'].length, dealName.length)}
        </Tag>
      );
    }
  }

  return (
    <Tag key="name" className={styles['offer-name']}>
      {dealName}
    </Tag>
  );
};

export default getNameBlock;
