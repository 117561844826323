import React from 'react';

import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import { sanitiseAiredaleStringParam } from '../../modules/setupModelWidget/getAirdaleInfo/sanitiseAiredaleStringParam';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { VultureRevealContext } from '../GenericComponents/VultureRevealContext';
import { VultureRevealContextProps } from '../GenericComponents/VultureRevealContext/types';

import { getCategoryAirdaleInfo } from './getCategoryAirdaleInfo';
import { ScrollableTileWrapper } from './ScrollableTileWrapper';

loadCSS('scrollable-tile');

interface ScrollableTileProps extends VultureRevealContextProps {
  airdaleCategoryData: Record<string, any> | null;
}

const ScrollableTile: React.FC<ScrollableTileProps> = (props) => {
  return (
    <VultureRevealContext {...props}>
      <ScrollableTileWrapper {...props} />
    </VultureRevealContext>
  );
};

ScrollableTile.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps },
  attributes,
  getJSON,
  editorial,
  defaultParams,
  endpoint,
  url,
  origin,
  dataLinkMerchant,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  if (attributes.modelName && site.vanillaName) {
    const airdaleData = (
      await getCategoryAirdaleInfo(
        site.vanillaName,
        `*${sanitiseAiredaleStringParam(attributes.modelName.substring(1))}*`,
        props.territory,
        getJSON,
      )
    ).body;

    const categoryData = airdaleData.find(
      (data) =>
        data.articlevoucherretailercategory.toLowerCase() === attributes.modelName?.toLowerCase(),
    );

    if (categoryData) {
      props.airdaleCategoryData = categoryData;
    }
  }

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default ScrollableTile;
