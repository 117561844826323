import React, { useState } from 'react';

import type { Event } from '@fie/analytics-library/src/types/Analytics';

import type { Deal } from '../../types/Deal';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/vulture-terms-conditions.css';

interface VultureTermsConditionsProps {
  deal: Deal;
}

const VultureTermsConditions: React.FC<VultureTermsConditionsProps> = ({ deal }) => {
  const [closed, setVisibility] = useState(true);
  const { translate, sendAnalytics } = useHawkWidgetContext();

  const onClick = (): void => {
    setVisibility((prevValue) => !prevValue);

    if (sendAnalytics) {
      sendAnalytics({
        clickType: 'internal',
        prefix: 'Click from',
        label: closed ? 'Opened Terms and Conditions' : 'Closed Terms and conditions',
      } as Event);
    }
  };

  return (
    <>
      <div
        className={styles['terms']}
        data-google-interstitial="false"
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
      >
        <span className={styles['terms-text']}>{translate('voucherTermsConditions')}</span>
        <span className={styles['terms-chevron']}>
          {closed ? <span>&#x25BC;</span> : <span>&#x25B2;</span>}
        </span>
      </div>
      <div className={styles[closed ? 'hidden-description' : 'description']}>
        {deal?.offer?.product_note}
      </div>
    </>
  );
};

export default VultureTermsConditions;
