import React from 'react';

import { useInView } from 'react-intersection-observer';

import { Deal } from '../../types/Deal';
import AffiliateLink from '../GenericComponents/AffiliateLink/AffiliateLink';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import ScrollerArrow from '../GenericComponents/Scroller/ScrollerArrow';

import { ScrollableTileProps } from './model';
import styles from './scrollable-tile.css';
import { ScrollableTiles } from './ScrollableTiles';

export const ScrollableTileWrapper: React.FC<ScrollableTileProps> = (props) => {
  const { airdaleCategoryData } = props;

  const { loadMoreDeals, widgetTitle, data, dealData } = useHawkWidgetContext();

  const { ref: firstTileRef, inView: firstTileInView } = useInView();
  const { ref: lastTileRef, inView: lastTileInView } = useInView();
  const scrollDistance = React.useRef(0);
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  // Calculate the scroll distance based on the width of the grid items and the container
  React.useEffect(() => {
    const calculateScrollDistance = (): void => {
      if (scrollableContainerRef.current) {
        scrollDistance.current = scrollableContainerRef.current.offsetWidth;
      }
    };

    calculateScrollDistance(); // Calculate initial scroll distance

    window.addEventListener('resize', calculateScrollDistance);

    return () => {
      window.removeEventListener('resize', calculateScrollDistance);
    };
  }, []);

  React.useEffect(() => {
    if (lastTileInView) {
      loadMoreDeals(true);
    }
  }, [lastTileInView]);

  const handleScrollRight = (): void => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft += scrollDistance.current + 34;
    }
  };

  const handleScrollLeft = (): void => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft -= scrollDistance.current + 34;
    }
  };

  const numOffers =
    data.counts.vouchers +
    data.counts.unique_codes +
    data.counts.offers +
    data.counts.deals +
    data.counts.offer_deals;

  return (
    <div className={styles.container}>
      <h2 className={styles['widget-title']}>{widgetTitle}</h2>
      <div className={styles['arrow-container']}>
        <ScrollerArrow
          disabled={firstTileInView}
          onClick={handleScrollLeft}
          direction="left"
          externalClassName={`${styles['scroll-arrow']} ${styles['scroll-arrow-left']}`}
        />
        <div className={styles['scrollable-tile-container']} ref={scrollableContainerRef}>
          <ScrollableTiles
            firstTileRef={firstTileRef}
            lastTileRef={lastTileRef}
            dealData={dealData}
          />
        </div>
        <ScrollerArrow
          disabled={lastTileInView}
          onClick={handleScrollRight}
          direction="right"
          externalClassName={`${styles['scroll-arrow']} ${styles['scroll-arrow-right']}`}
        />
      </div>
      {Boolean(airdaleCategoryData?.canonical) && (
        <div className={styles['container-all-deals']}>
          <AffiliateLink
            clickType="internal"
            deal={{} as Deal}
            customUrl={airdaleCategoryData?.canonical}
          >
            See all {numOffers} deals
          </AffiliateLink>
        </div>
      )}
    </div>
  );
};
