export default (): number => {
  const localStorage = typeof window !== 'undefined' && window.localStorage;

  if (localStorage && localStorage.requestId) {
    return Number(localStorage.requestId);
  }
  const randomNumber = Date.now() + Math.floor(Math.random() * 100);
  if (localStorage) {
    localStorage.setItem('requestId', randomNumber.toString());
  }
  return randomNumber;
};
