import { getJSON } from '../../../assets/modules/ajax';
import type { RedeemResponse } from '../../../assets/types/Response';
import type { Site } from '../../modules/sites/types/Site';
import type { Deal } from '../../types/Deal';
import type { RequestType } from '../../types/GetJSON';
import type { Translate } from '../../types/Localiser';

import getRequestId from './getRequestId';

export default async (
  endpoint: string,
  site: Site,
  area: string,
  deal: Deal,
  translate: Translate,
): Promise<RedeemResponse | Record<string, never>> => {
  const redeemEndpoint = `${endpoint}/redeem.php`;
  const dataset = {
    site: site.id,
    area,
    request_id: getRequestId(),
    match_id: deal?.match_id,
  };
  const response = await getJSON<RequestType.REDEEM_REQUEST>(redeemEndpoint, dataset);
  const uniqueCodeObject = response.body;

  if (uniqueCodeObject && !uniqueCodeObject.code) {
    throw translate('ranOutOfCodesMessage');
  }
  return uniqueCodeObject;
};
