import React from 'react';

import type { WidgetProps } from '../../../types/WidgetProps';
import AffiliateLink from '../../GenericComponents/AffiliateLink/AffiliateLink';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { VultureButtonAffiliateLinkWrapper } from '../../GenericComponents/VultureButton/VultureButtonAffiliateLinkWrapper';
import { Tile } from '../Tile';

import styles from './scrollable-tiles.css';

interface ScrollableTilesProps {
  dealData: WidgetProps['dealData'];
  firstTileRef: (node?: Element | null) => void;
  lastTileRef: (node?: Element | null) => void;
}

export const ScrollableTiles: React.FC<ScrollableTilesProps> = (props) => {
  const { firstTileRef, dealData, lastTileRef } = props;

  const { seenMatchId, setSeen, setPopupDeal } = useHawkWidgetContext();

  return (
    <>
      {dealData.deals.map((deal, i) => {
        const tileRef = {
          0: firstTileRef,
          [dealData.deals.length - 1]: lastTileRef,
        }[i];
        const { merchant, promos, offer } = deal;
        const TileComponent = (): JSX.Element => {
          return (
            <Tile
              logoUrl={merchant.logo_url}
              title={merchant.name || ''}
              exclusive={promos?.some((promo) => promo.type === 'exclusive')}
              description={offer.name}
              promos={promos}
            />
          );
        };

        if (deal.airdale_info?.canonicalUrl) {
          return (
            <div ref={tileRef || null} className={styles['tile-link-container']}>
              <AffiliateLink
                clickType="internal"
                deal={deal}
                customUrl={deal.airdale_info?.canonicalUrl}
              >
                <TileComponent />
              </AffiliateLink>
            </div>
          );
        }

        const arrayOfSeen = [
          ...((seenMatchId && seenMatchId.split(',')) || []),
          String(deal?.match_id),
        ];
        const stringOfSeen = arrayOfSeen.join(',');
        return (
          <div ref={tileRef || null} className={styles['tile-link-container']}>
            <VultureButtonAffiliateLinkWrapper
              setPopupDeal={setPopupDeal}
              deal={deal}
              setSeen={setSeen}
              elementType="container"
              stringOfSeen={stringOfSeen}
            >
              <TileComponent />
            </VultureButtonAffiliateLinkWrapper>
          </div>
        );
      })}
    </>
  );
};
