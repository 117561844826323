import React, { useEffect } from 'react';

import { DialogOverlay } from '../GenericComponents/DialogOverlay';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import getRequestId from './getRequestId';
import getUniqueCode from './getUniqueCode';
import styles from './styles/vulture-popup.css';
import VultureInputs from './VultureInputs';
import VultureTermsConditions from './VultureTermsConditions';

const VulturePopup: React.FC = () => {
  const {
    popupDeal,
    setPopupDeal,
    voucherCode,
    translate,
    endpoint,
    site,
    area,
    uniqueCodeData,
    setuniqueCodeData,
    setApiCall,
    setLoading,
    genericSharedComponents: { Button, DealImage, DisplayName },
  } = useHawkWidgetContext();

  useEffect(() => {
    const getUniquiCode = async (): Promise<void> => {
      try {
        setLoading(true);
        const uniqueCode = await getUniqueCode(endpoint, site, area, popupDeal, translate);

        setLoading(false);
        setApiCall('success');

        setuniqueCodeData({
          code: uniqueCode.code,
          request_id: uniqueCode.request_id,
          message: uniqueCode.message,
          area: uniqueCode.area,
        });
      } catch (e) {
        setLoading(false);
        setApiCall('failure');

        setuniqueCodeData({
          code: translate('ranOutOfCodesMessage'),
          request_id: getRequestId(),
          message: translate('ranOutOfCodesMessage'),
          area,
        });
      }
    };
    if (voucherCode && voucherCode.product_type && voucherCode.product_type === 431) {
      getUniquiCode();
    }
  }, [popupDeal]);

  if (popupDeal) {
    const merchantName = popupDeal.merchant && popupDeal.merchant.name;

    if (merchantName) {
      const handleCloseDialog = (): void => {
        setPopupDeal(null);
      };

      return (
        <DialogOverlay closeDialog={handleCloseDialog}>
          <div className={styles['container']}>
            <div className={styles['main']}>
              <DealImage
                deal={popupDeal}
                priority={['merchant']}
                className="vulture"
                width={120}
                height={95}
              />
              <DisplayName deal={popupDeal} type="product" className={styles['display-name']} />
              <VultureInputs
                voucherCode={voucherCode}
                deal={popupDeal}
                uniqueCodeData={uniqueCodeData}
              />
              <Button
                deal={popupDeal}
                className={voucherCode ? 'vulture-code' : 'vulture-discount'}
                displayMerchant={true}
                buttonText={
                  popupDeal.merchant.name
                    ? translate('voucherMerchantMessage', [popupDeal.merchant.name])
                    : translate('voucherMerchantMessage')
                }
                clickType="go to retailer"
              />
            </div>
            {popupDeal && popupDeal.offer && popupDeal.offer.product_note && (
              <div className={styles['terms']}>
                <VultureTermsConditions deal={popupDeal} />
              </div>
            )}
          </div>
        </DialogOverlay>
      );
    }
  }
  return null;
};

export default VulturePopup;
